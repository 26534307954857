import { Modal, Input } from '@nbit/arco'
import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import Icon from '@/components/icon'
import { CoinStateEnum, CoinListTypeEnum, getAssetsDepositType } from '@/constants/assets'
import { AllCurrencyListResp } from '@/typings/api/assets/fiat'
import { getCurrencyList } from '@/apis/assets/fiat'
import cn from 'classnames'
import LazyImage from '@/components/lazy-image'
import { useAssetsStore, baseAssetsStore } from '@/store/assets'
import { oss_area_code_image_domain_address } from '@/constants/oss'
import { useCommonStore } from '@/store/common'
import { CurrencyModeErationEnum } from '@/constants/common'
import styles from './index.module.css'

type ICoinListProps = {
  type: CoinListTypeEnum
  isShow: boolean
  setShow(val): void
  onChangeCoin(val): void
  // 当前币种
  currentCoin?: string
  /** 多币种下币种数据 */
  withdrawalCoinList?: AllCurrencyListResp[]
}
/**
 * 币种选择列表组件
 */
export function CoinList({ type, isShow, setShow, onChangeCoin, currentCoin, withdrawalCoinList }: ICoinListProps) {
  const { localeInfo } = useCommonStore()
  const [coinListData, setCoinListData] = useState<AllCurrencyListResp[]>([])
  const [coinListTable, setCoinListTable] = useState<AllCurrencyListResp[]>(withdrawalCoinList || [])
  const [coinListHot, setCoinListHot] = useState<AllCurrencyListResp[]>([])
  const assetsStore = useAssetsStore()
  const { currencySearchHistory, updateCurrencySearchHistory } = assetsStore || {}
  const historyList = currencySearchHistory[getAssetsDepositType(type)] || [] // 搜索历史

  // 数组排序
  const sortArrayFn = (x, y) => x.fiatCode?.toUpperCase().localeCompare(y.fiatCode?.toUpperCase()) || 0

  /** 本地搜索 */
  const onSearch = searchKey => {
    const searchResults = searchCoinList(coinListData, searchKey, false)
    let sortCoinList = searchResults.slice(0)
    sortCoinList = sortCoinList.sort(sortArrayFn)
    setCoinListTable(sortCoinList)
  }

  const searchCoinList = (dataList: any | undefined, searchKey: string, hideLessState: boolean) => {
    if (!searchKey && !hideLessState) {
      return dataList
    }

    dataList =
      !!dataList &&
      dataList.filter((item: { fiatCode: string; totalAmount: number }) => {
        const ignoreCaseKey = searchKey.toUpperCase()
        return item.fiatCode.toUpperCase().includes(ignoreCaseKey) && (!hideLessState || item.totalAmount > 0)
      })

    return dataList
  }

  const onClickCoin = (coin: AllCurrencyListResp) => {
    if (coin?.frozen) {
      return
    }
    const isExist = historyList.some(item => item.fiatCode === coin.fiatCode)
    if (historyList.length < 8) {
      !isExist && updateCurrencySearchHistory(type, [coin, ...historyList])
    } else {
      if (!isExist) {
        let newHistoryList = [...historyList]
        newHistoryList.pop()
        newHistoryList.unshift(coin)
        updateCurrencySearchHistory(type, newHistoryList)
      }
    }
    onChangeCoin(coin)
    setShow(false)
  }

  /**
   * 获取法币列表
   */

  const getCurrencyListInfo = async () => {
    const res = await getCurrencyList({})
    if (!res?.data) return
    const { data } = res
    if (data.length === 0) return
    assetsStore.updateCurrencyInfoList(data)
    let sortCoinList = [...data]
    sortCoinList = sortCoinList.sort(sortArrayFn)

    setCoinListData(sortCoinList)
    setCoinListTable(sortCoinList)
  }

  /** 充提币状态 */
  const getCoinState = (item: { isDeposit: any; isWithdraw: any }) => {
    if (type === CoinListTypeEnum.deposit) {
      return item.isDeposit === CoinStateEnum.open
    }
    if (type === CoinListTypeEnum.withdraw) {
      return item.isWithdraw === CoinStateEnum.open
    }
    return false
  }

  const showCoinInfoNode = item => {
    if (item?.frozen) {
      return (
        <>
          <div className="coin-name text-text_color_04">
            {/* <span>{item.symbol}</span> */}
            <span>{item?.fiatCode}</span>
          </div>
          <div className="coin-state">{t`assets.deposit.suspended`}</div>
        </>
      )
    }
    return (
      <div className={cn('coin-name', { active: item?.fiatCode === currentCoin })}>
        <span>{item?.fiatCode}</span>
      </div>
    )
  }

  useEffect(() => {
    localeInfo?.currencyMode !== CurrencyModeErationEnum.multiCurrency && getCurrencyListInfo()
  }, [])

  return (
    <Modal
      className={styles.scoped}
      title={<div style={{ textAlign: 'left' }} className="title-coin">{t`assets.deposit.coinOption`}</div>}
      style={{ width: 480 }}
      visible={isShow}
      footer={null}
      onCancel={() => {
        setShow(false)
      }}
    >
      <div className="px-8">
        <Input
          className="search-form"
          style={{ width: 416, height: 46 }}
          prefix={<Icon name="search" className="search-icon" />}
          placeholder={t`assets.deposit.searchCoin`}
          onChange={value => onSearch(value)}
        />
        {historyList && historyList.length > 0 && (
          <div className="mt-6">
            <div className="label-name search-title">
              <span>{t`features_market_market_list_market_list_spot_trade_layout_coin_selected_history_index_2739`}</span>
              <Icon
                name="rebates_delete"
                hasTheme
                className="text-lg"
                onClick={() => updateCurrencySearchHistory(type, [])}
              />
            </div>
            <div className="search-history">
              {historyList.map((item, index) => (
                <div
                  className="search-coin-item"
                  key={index}
                  onClick={() => {
                    onClickCoin(item)
                  }}
                >
                  {item.fiatCode}
                </div>
              ))}
            </div>
          </div>
        )}
        {coinListHot && coinListHot.length > 0 && (
          <>
            <div className="label-name mt-6">{t`assets.deposit.hotCoin`}</div>
            <div className="hot-coin">
              {coinListHot.map((item, index) => (
                <div
                  className="hot-coin-item"
                  key={index}
                  onClick={() => {
                    onClickCoin(item)
                  }}
                >
                  <LazyImage
                    src={item?.logo ? item?.logo : `${oss_area_code_image_domain_address}${item?.country}.png`}
                  />
                  <span>{item.fiatCode}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {coinListTable?.length > 0 && (
        <div className="coin-list-wrap">
          <div className="coin-list">
            {coinListTable.map((item, index) => (
              <div
                key={index}
                className="coin-item"
                onClick={() => {
                  onClickCoin(item)
                }}
              >
                <LazyImage
                  src={item?.logo ? item?.logo : `${oss_area_code_image_domain_address}${item?.country}.png`}
                  width={24}
                  height={24}
                />
                {showCoinInfoNode(item)}
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  )
}
