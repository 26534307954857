/**
 * 充值组件
 */
import cn from 'classnames'
import { t } from '@lingui/macro'
import { Button } from '@nbit/arco'
import { useRequest } from 'ahooks'
import { useCommonStore } from '@/store/common'
import { useState, useEffect } from 'react'
import { CoinListTypeEnum } from '@/constants/assets'
import { getPayCharge, getFiatPrice, postFiatPayCharge } from '@/apis/assets/fiat'
import Icon from '@/components/icon'
import { AllChargeListResp, AllChargePayInfoResp, ChargeChannels, AllFiatCurrencyResp } from '@/typings/api/assets/fiat'
import { link } from '@/helper/link'
import { formatCurrency } from '@/helper/decimal'
import { oss_svg_image_domain_address } from '@/constants/oss'
import LazyImage from '@/components/lazy-image'
import GameFiatTrigger from '@/features/recreation/theme/components/game-fiat-trigger'
import { WadvertisingConfigResp } from '@/typings/api/assets/assets'
import { businessId } from '@/helper/env'
import { TerminalRouteKeyEnum } from '@/constants/advert'
import { usePageContext } from '@/hooks/use-page-context'
import { getAssetsAllRoutePath } from '@/helper/route'
import { CurrencyModeErationEnum } from '@/constants/common'
import TradeInputNumber from '@/components/trade-input-number'
import { CoinList } from '../coin-list/index'
import { FiatAdvertise } from './advertise'
import styles from './index.module.css'

interface depositLayoutProps {
  /** 支付方式 */
  fiatPayInfo: AllChargePayInfoResp[]
  /** 充值档次 */
  topUpGradList: AllChargeListResp[]
  /** 初始法币 */
  firstFiatCode: string
  /** 当前支持的法币 */
  fiatCurrency: AllFiatCurrencyResp
  /** 关闭弹窗回调 */
  onclick: () => void
  /** 收款充值货币回调 */
  changeCharge: (val) => void
  fastManyBusiness: WadvertisingConfigResp
}

export function FiatDepositLayout({
  onclick,
  fiatPayInfo,
  topUpGradList,
  firstFiatCode,
  fiatCurrency,
  changeCharge,
  fastManyBusiness,
}: depositLayoutProps) {
  const offset = 2
  const { localeInfo } = useCommonStore()
  const pageContext = usePageContext()
  const [currentValue, seCurrentValue] = useState(firstFiatCode) // 充值金额单位
  const [fiatCode, setFiatCode] = useState(firstFiatCode)
  const [amount, setAmount] = useState<string>() // 充值金额
  const [visibleCoinList, setVisibleCoinList] = useState(false) // 币种选择列表展示状态
  const [buttonList, setButtonList] = useState<ChargeChannels[]>(fiatPayInfo[0]?.routes || []) // 支付通道
  const [activeLever, setActiveLever] = useState<AllChargeListResp | null>(null) // 充值档次选择
  const [defaultPayment, setDefaultPayment] = useState({
    currentChannel: '',
    payInfo: {} as AllChargePayInfoResp,
    popupVisible: false as boolean,
  })
  const [discountList, setDiscountList] = useState<AllChargeListResp[]>([])
  const [defaultButtonList, setDefaultButtonList] = useState<ChargeChannels[]>([])
  // 支付通道选择
  const [routesData, setRoutesData] = useState<ChargeChannels>()

  const handleButtonClick = value => {
    if (localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency) {
      setDiscountList(value?.levels || [])
    }
    setRoutesData(value)
  }

  /** 选择主币 */
  const onChangeCoin = coinInfo => {
    changeCharge(coinInfo?.fiatCode)
  }

  useEffect(() => {
    const routes = fiatPayInfo[0]?.routes
    setDefaultPayment({
      ...defaultPayment,
      payInfo: fiatPayInfo[0],
      currentChannel: fiatPayInfo[0]?.channelName || fiatPayInfo[0]?.groupCode || '',
    })
    setDefaultButtonList(routes || [])
    setRoutesData((routes && routes[0]) || ({} as ChargeChannels))
  }, [fiatPayInfo])

  useEffect(() => {
    if (fiatCurrency && fiatCurrency?.fiatCode) {
      setFiatCode(fiatCurrency?.fiatCode)
      seCurrentValue(fiatCurrency?.fiatCode)
    }
  }, [fiatCurrency])

  // 获取法币对 USDT 的汇率
  const { data: dataRes, run: getPrice } = useRequest(getFiatPrice, { manual: true })
  // 支付页面页脚信息
  // const { data: ChargeInfoRes, run: getChargeInfoRes } = useRequest(getChargeInfo)

  // 监听选中法币的变化
  useEffect(() => {
    getPrice({ fiatCode })
  }, [fiatCode])

  const deposit = async () => {
    const data = {
      amount: Number(amount),
      /** 充值法币编号 */
      fiatCode,
      /** 充值优惠的 id，如果该值不为空，则金额，法币编号以该配置为准 */
      levelId: activeLever?.id || '',
      id: routesData?.id || 0,
      redirectUrl: `${window.location.href}${
        pageContext?.path === getAssetsAllRoutePath() ? '' : TerminalRouteKeyEnum.assets
      }`,
    }
    const res =
      localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
        ? await postFiatPayCharge(data)
        : await getPayCharge(data)
    if (!res.data) return
    const url = res.data.url
    onclick()
    link(url)
  }

  const { loading, run: getDeposit } = useRequest(deposit, { manual: true })

  const handleLeverClick = lever => {
    if (lever === activeLever) {
      setActiveLever(null)
      setAmount('')
    } else {
      setActiveLever(lever)
      setAmount(lever.amount)
    }
  }

  useEffect(() => {
    if (topUpGradList && topUpGradList.length > 0) {
      setDiscountList(topUpGradList)
    }
  }, [topUpGradList])

  return (
    <div className={styles.scoped}>
      <div className="brl-header">
        <p className="brl-title">
          {currentValue} {t`features_home_hero_banner_index_flh6elz9z2`}
        </p>
        <div className="brl-header-icon">
          <Icon name="close" fontSize={20} onClick={onclick} className="close-icon" />
        </div>
      </div>
      <GameFiatTrigger
        setRoutesData={setRoutesData}
        setButtonList={setButtonList}
        onChangeDiscountList={setDiscountList}
        defaultPayment={defaultPayment}
        fiatPayInfo={fiatPayInfo}
        setDefaultPayment={setDefaultPayment}
      />
      <div className="button-box">
        {buttonList.length === 0
          ? defaultButtonList.map((value, index) => (
              <Button
                key={index}
                onClick={() => handleButtonClick(value)}
                className={cn('button-style', {
                  active: value?.name === routesData?.name,
                })}
              >
                {value?.name}
              </Button>
            ))
          : buttonList.map((value, index) => (
              <Button
                key={index}
                onClick={() => handleButtonClick(value)}
                className={cn('button-style', {
                  active: value?.name === routesData?.name,
                })}
              >
                {value?.name}
              </Button>
            ))}
      </div>
      <p className="money">{t`features_assets_main_deposit_fiat_deposit_index_7cdgpvv_vl`}</p>
      <TradeInputNumber
        precision={offset}
        min={routesData?.min || 0}
        max={routesData?.max || 10000000000}
        placeholder={t({
          id: 'features_assets_main_deposit_fiat_deposit_index_8hg3pqloyh',
          values: { 0: formatCurrency(routesData?.min || 0, offset) },
        })}
        onChange={val => {
          setAmount(val.toString())
          setActiveLever(null)
          const matchedItem = discountList?.find(item => item.amount === val)
          if (matchedItem) {
            setActiveLever(matchedItem)
          }
        }}
        value={amount}
        // suffix={
        //   <div className="flex items-center">
        //     <span className="current-value">{currentValue}</span>
        //     <Icon
        //       name="icon_agent_center_drop"
        //       fontSize={8}
        //       className="center-drop ml-2"
        //       onClick={() => {
        //         setVisibleCoinList(true)
        //       }}
        //     />
        //   </div>
        // }
      />
      {/* <p className="money mt-1">
        ≈ {faitAmount(amount, Number(dataRes?.data?.rate), false, offset, true)}
        {dataRes?.data?.cryptCode}
      </p> */}
      <div className="all-box">
        {discountList?.map((i, index) => (
          <div
            key={index}
            className={cn('small-box', {
              active: i.amount === activeLever?.amount,
            })}
            onClick={() => {
              handleLeverClick(i)
            }}
          >
            <div className="all-flex">
              <div>
                {localeInfo?.currencyMode !== CurrencyModeErationEnum.multiCurrency && i?.logo && (
                  <LazyImage src={i?.logo || ''} className="fiat-logo" />
                )}
              </div>
              <div>
                <p className="coin">{`${i?.symbol} ${i?.amount}`}</p>
              </div>
            </div>

            {i?.bonus && Number(i?.bonus) > 0 ? (
              <p className="bonus">
                {' '}
                + {i?.bonus || '--'}% {t`features_assets_main_deposit_fiat_deposit_index_c5fkhy9xnz`}
              </p>
            ) : null}

            {i?.isHot && (
              <LazyImage
                src={`${oss_svg_image_domain_address}icon_fiat_hot_tag${
                  i.amount === activeLever?.amount ? '_active' : ''
                }.png`}
                className="hot-wrap"
              />
            )}
          </div>
        ))}
      </div>
      <Button type="primary" className={'deposit'} onClick={getDeposit} disabled={!amount} loading={loading}>
        {t`features_assets_main_deposit_fiat_deposit_index_61yycb8l5o`}
      </Button>
      {/* TODO: 910000 商户下展示固定的图片 (240057 兼容测试环境)
       */}
      <FiatAdvertise
        onlyImg={
          fastManyBusiness?.MerchantProduct?.includes(Number(businessId)) ||
          fastManyBusiness?.MerchantTest?.includes(Number(businessId))
        }
      />
      <div className="button-title">
        <div>
          {/* <div className="orange"></div> */}
          {/* 底部文案，接口后端说先不弄，所以先注释掉 */}
        </div>
      </div>
      {/* 币种选择 */}
      {visibleCoinList && (
        <CoinList
          type={CoinListTypeEnum.deposit}
          onChangeCoin={onChangeCoin}
          isShow={visibleCoinList}
          setShow={setVisibleCoinList}
          currentCoin={currentValue}
        />
      )}
    </div>
  )
}
